<template>
  <div class="container-fluid health">
    <!-- LOADING CONTENT -->
    <!-- END OF LOADING CONTETN -->
    <div class="row">
      <div class="col-md-12 pb-5 text-center">
        <h1 class="title">In Association with the Africa CDC</h1>
      </div>
    </div>

    <div class="row pt-4 ">
      <div class="col-md-6 col-lg-4 col-xl-4  card_container mb-4" v-for="(resource,key) in healthResources" :key="key">

          <a target="_blank" :href="resource.sourceUrl">
            <div class="news">
            <img
              :src="`${appUrl()}${resource.captionImage}`"
              class="img-responsive"
              alt=""
            />
            <h5 class="pt-4 card_text">{{resource.title}}</h5>
          </div>
          
            <div class="row justify-content-center pt-1  publish">
              <div class="col-7 col-md-7 col-lg-7">
                <small>Published by {{resource.publishedBy}}</small>
              </div>
              <div class="col-5 col-md-5 col-lg-5 text-right">
                <a target="_blank" class="download_btn" :href="resource.sourceUrl">Read more</a>
              </div>
            </div>
          </a>
      </div>
    </div>
  </div>
</template>

<script>
import url from '../../helpers/url'
import Storage from '../../helpers/storage'
import { mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'

export default {
  name:'health',
  data() {
    return {
      token:'',
      fileInput:'',
      formData: {
        title:'',
        author:'',
        createdOn:[],
        publishedBy:'',
        sourceUrl:'',
        captionImage:''
      },
      captionError:'',
      success: '',
      error:'',
      loading:false,
      resource: {

      },
      healthResources:[],
      resourceError:null,
      id:'',
      key:'',
      imageUrl:'',
    }
  },
  computed:{
    ...mapGetters(['currentUser'])
  },

  
  methods: {
    chooseImage(){
      this.$refs.fileInput.click()
    },
    reset() {
      this.formData = {

      }
      this.error = null
      this.loading = false
      this.success = null
    },
    moment() {
      return moment()
    },
    appUrl() {
      return url()
    },
    getResourceID(id, key) {
      this.success = null
      this.error = null
      this.loading = false

      this.id = id
      this.key = key
    },
    
    async editHealthResource(){
      try {
        
        this.success = null
        this.error = null
        this.loading = true

       let newFormData = new FormData();

        if (!this.formData.captionImage) {

            this.loading = false
            this.captionError = "No file uploaded"
            return

        } else {

          this.captionError = null

        }
      
        if(this.formData.createdOn){
           let formartDate = new Date(this.formData.createdOn).toISOString();
           newFormData.append("createdOn", formartDate);
        } else {
          newFormData.append("createdOn", "");
        }

        if(this.formData.author) {
          newFormData.append("author", this.formData.author);
        } else {
          newFormData.append("author", "");
        }

        newFormData.append("title", this.formData.title);
        
        newFormData.append("publishedBy", this.formData.publishedBy);
       
        newFormData.append("sourceUrl", this.formData.sourceUrl);

        console.log('caption image', this.formData.captionImage)
        console.log('author', this.formData.author)

        axios.defaults.headers.common['Authorization'] = this.token
        const response = await axios.patch(`${url()}/api/blog/update-health/${this.id}/`)

        this.success = "Health resource edited"
        this.healthResources[this.key] = response.data

      } catch (err) {
        console.log(err)
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network Error. Try again."
        }
      }
    },

    async deleteHealthResource(){
      try {
        this.success = null
        this.error = null
        this.loading = true
        const response = await axios.delete(`${url()}/api/blog/delete-health/${this.id}`,
        {
          headers: {
            "Authorization": this.token
          }
        })
        this.success = "Health resource deleted"
        this.healthResources.splice(this.key,1)
      } catch (err) {
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network Error. Try again."
        }
      }
    },

    async fetchHealthResources() {
      try {
        const response = await axios.get(`${url()}/api/blog/fetch-health`)
        this.healthResources = response.data.Health
      } catch (err) {
        if(err?.response?.data == undefined) {
          this.resourceError = "Network Error. Try again."
        }
      }
    },

    getResource(data, key) {
      this.success = null
      this.error = null
      this.loading = false

      this.id = data.id
      this.key = key
      this.formData.title = data.title
      this.formData.publishedBy = data.publishedBy
      this.formData.author = data.author === "undefined" ? " ": data.author
      this.formData.sourceUrl = data.sourceUrl
      this.formData.createdOn = data.createdOn ? moment(data.createdOn).format('').split("T")[0] : ''
      this.formData.captionImage = data.captionImage
      this.imageUrl = `${url()}${data.captionImage}`
      // 
    },
    handleCaptionImage(e) {

      let files = e.target.files;

      this.formData.captionImage = files[0]

      if(!this.formData.captionImage) {

       this.captionError = "Caption image is required."

      }
      // change update image 
      // this file reader wil read and remove the already existing image from the ui
      if (files && files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
           document.getElementById('preview_image').src  = e.target.result;
        }
        reader.readAsDataURL(files[0]);
    }

    },

    async addHealthResource() {
    
      try {
        this.loading = true
        this.error = null
        this.success = null

        // validating caption image
        let newFormData = new FormData()
        if (!this.formData.captionImage) {
            this.loading = false
            this.captionError = "No file uploaded"
            return
        } else {
          this.captionError = null
        }
        if(this.formData.createdOn){
           formartDate = new Date(this.formData.createdOn).toISOString();
           newFormData.append("createdOn", formartDate);
        } else {
          newFormData.append("createdOn", '');
        }
        if(this.formData.author) {
          newFormData.append("author", this.formData.author);
        } else {
          newFormData.append("author", '');
        }
       
        newFormData.append('title',this.formData.title)
        newFormData.append('publishedBy', this.formData.publishedBy)
        newFormData.append('sourceUrl', this.formData.sourceUrl)
        
        //  headers: {
        //               'Authorization':`${Vue.prototype.$session.get('entity')}`
        //           }
        const response = await axios.post(`${url()}/api/blog/add-health`,
        newFormData, 
        {
          headers: {
            "Authorization": this.token
          }
        }
        )
        
        this.healthResources.push(response.data.Health)
        this.loading = false
        this.success = "Health resource uploaded successfully."
        this.formData = {

        }
        

      } catch (err) {
        console.log(err)
        this.loading = false
        if(err?.response?.data == undefined) {
          this.error = "Network error, check your connection and try again"
        }
      }
    
      
    }
  },
  created(){
    this.token = Storage.get('token')
    this.fetchHealthResources()
  }
};
</script>

<style  scoped>
.title{
  color:#000;
  text-align: center;
  font-size: 2em;
}
.resource{
  background-color:rgb(235, 41, 41);
  float:right;
  border:none;
  outline:none;
  color:#fff;
  font-size: 1.2em;
  padding:.6em;
  transition: all 0.5s;
}
.resource:hover{
  outline: none;
  border:none;
  opacity: .7;
}
.association{
  font-weight: bold;
  padding-top:.1em;
  padding-bottom: 2em;
  font-size: 1.4em;
}
.health{
  padding-top: 5em;
  padding-bottom: 7em;
  padding-right: 12%;
  padding-left: 12%;
}
hr {
  border: 1px solid #000;
}
.img-responsive {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
a {
  text-decoration: none;
  color:#000;
}
.more {
  color: #8c8c8c;
}
.view_more{
  text-decoration: none;
  background-color: transparent;
  color: #8c8c8c;
}
.iconify {
  border: 1px solid #8c8c8c;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:7px 10px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.view_more:focus, .view_more:active, .view_more:hover, .download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
@media screen and (max-width: 1100px) {
  .health{
  padding: 4em  5% 0 5%;
}
}
@media screen and (max-width: 768px) {
  .association{
    font-size: 1em;
  }
  .news{
    margin-bottom: 5px;
    /* padding: 10px; */
  }
  div.card_container:not(:last-of-type){
    margin-bottom:3em;
  }
}
@media screen and (min-width:768px){
  .card_text{
    height: 160px;
  }
}
</style> 