<template>
    <div class="container-fluid footer">
      <div class="row justify-content-center ">
        <div class="col-md-3 logo">
         
          <!-- <div class=""> -->

            <div class="logo_logo c">
              <img src="../../assets/new/AfCFTA.png" height="60" alt="" />
            </div>

            <div class="logo_text">
              <p class="big"><strong>AfCFTA</strong></p>
              <p>BLOG</p>
              <!-- <p class="small">AN INITIATIVE OF THE AfCFTA</p>
              <p class="small">SECRETARIAT</p> -->
            </div>

          <!-- </div> -->

        </div>
        <div class="col-md-3">
          <ul>
            <!-- <li><router-link to="/#">Contest</router-link></li> -->
            <li><a href="https://afcfta.blog/events">Debate</a></li>
            <li><a href="https://afcfta.blog/debates">Events</a></li>
            <li><a href="https://afcfta.blog/resources">Resources</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <ul>
            <li><a to="https://afcfta.blog/#health">Health</a></li>
            <li><a to="https://afcfta.blog/#entrepreneurship">Entrepreneurship</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <!-- <ul>
            <li><router-link to="/">Contact</router-link></li>
            <li><router-link to="/">Newsletter</router-link></li>
          </ul> -->
        </div>
      </div>
      <div class="row justify-content-center copyright-logos py-5">
        <div class="col-md-6 mb-2 text-center copyright">
          <p>
            Copyright 2021 AfCFTA Blog. All rights reserved. Privacy and Terms
            of Use.
          </p>
        </div>
        <div class="col-md-5 offset-md-1">
          <div class="d-flex justify-content-center logo-img">
            <img src="../../assets/afcft.jpg"  class="mr-4" height="50px" alt="" />
            <img
              src="../../assets/new/7 2B.05702b7b.png"
              height="50px"
              alt=""
              class="mr-4"
            />
            <img
              src="../../assets/new/auweblogo-en.d2ac7604.png"
              height="50px"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.footer{
  padding-right: 12%;
  padding-left: 12%;
  padding-top:100px;
  background: #1F1F1E;
  color: #fff;
}
ul {
  list-style: none;
  
}
li {
  text-decoration: none;
  font-size: 18px;
  padding-top: 10px;
}

a {
  color: #fff;
  text-decoration: none;
}
.big strong{
  font-weight: 900;
}
.copyright p {
  font-size: .9em;
}
a:hover{
  opacity: 0.4;
}

.logo{
  display: flex;
}
.logo-img{
  margin-top: -10px;
}
 .logo_logo{
  margin-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 10px;
}
.logo_text{
   border-left: 2px solid rgb(63, 62, 62);
   padding-left: 5px;
   padding-top:7px;
   margin-top:10px;
   margin-bottom: 10px;
   height: 85px;
}
.logo_text P{
  padding:0;
  margin:0;
}
.logo_text small {
  padding: 0;
  margin: 0;
  line-height: 0px;
  font-size: .5em;
}

@media screen and (max-width: 1100px) {
  .footer{
  padding-right: 5%;
  padding-left: 5%
}
}
@media screen and (max-width: 768px) {
 .copyright-logos{
  flex-direction: column-reverse;
}
.logo-img{
  margin-bottom:1.5em;
}
}

</style>