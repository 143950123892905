<template>
  <main>
    <nav class="container-fluid">
      <input id="nav-toggle" type="checkbox"/>

      <div class="logo">
        <a href="https://afcfta.blog/">
          <div class="logo_logo">
            <img
            src="../../assets/new/AfCFTA Logo.png"
            height="60px"
            class="img-responsive mt-3"
            alt=""
          />
          </div>
          <div class="logo_text">
            <p class="big"><strong>AfCFTA</strong> BLOG</p>
            <p class="small">AN INITIATIVE OF THE AfCFTA</p>
            <p class="small">SECRETARIAT</p>
          </div>
        </a>
      </div>

      <ul class="links">
        <li><router-link  to="/">
            <img src="../../assets/new/arfricacdc.jpeg" height="60" alt="">
        </router-link></li>
        <!-- <li :class="[$route.name === 'contest' ? 'active' : '']"><router-link to="/contest">Contests</router-link></li> -->
        <!-- <li :class="[$route.name === 'debates' ? 'active' : '']"><router-link to="/debates">Debates</router-link></li>
        <li :class="[$route.name === 'events' ? 'active' : '']"><router-link to="/events">Events</router-link></li>
        <li id="res" :class="[$route.name === 'resources' ? 'active' : '']"><router-link to="/resources">AfCFTA Resources</router-link></li> -->

        <!-- <div class="nav-icons">
          <div class="d-flex" v-if="!user">
            <a class=" " href='/redirect?service=register'>Signup</a> <span class="pt-3">/</span>
            <a class=" " href='/redirect?service=login'>Login</a>
          </div>
          <a class="btn btn-outline-danger nav-link" v-if="user" @click="logout"
          :href="`${url()}/accounts/logout?${service()}`">Logout</a>

        </div> -->
      </ul>

      <!-- <label for="nav-toggle" class="icon-burger">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </label> -->

    </nav>
  </main>
</template>

<script>
import VueSessionStorage from 'vue-sessionstorage'
import Storage from '../../helpers/storage'
import url from '../../helpers/url'
import service from '../../helpers/service'
export default {

  methods: {
      url() {
      return url()
    },
    service() {
      return service()
    },
    logout() {
      Storage.removeAll()
    },
    getUser() {
      this.user = Storage.get('token')
    },
  },
  created() {
    this.getUser()
  }
  
};
</script>

<style  scoped>
main {
  background: #fff;
  width: 100%;
  padding-top:110px;
  position: fixed;
  z-index: 10;
}
nav {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  font-family: "Montserrat" sans-serif;
  width: 100%;
  padding-right:12%;
  padding-left: 12%;
  border-bottom: 2px solid black;
}
 .logo_logo{
  margin-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 10px;
}
.logo_text{
   border-left: 2px solid rgb(63, 62, 62);
   padding-left: 5px;
}
.logo_text P{
  padding:0;
  margin:0;
  line-height: 1.2em;
}
.logo_text small {
  padding: 0;
  margin: 0;
  line-height: 0px;
  font-size: .5em;
}
#res{
  margin-right: 40px;
}

.iconify{
  margin-right: 20px;
}

nav .logo a {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color:#000;
}

nav .links {
  float: right;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

nav .links li {
  list-style: none;
  position: relative;
  padding-top:15px;
  /* background-color: #DC3545; */
}


nav .links a {
  display: block;
  padding: 1em;
  font-size: 16px;
  /* font-weight: bold; */
  color: #000;
  text-decoration: none;
}

#nav-toggle {
  position: absolute;
  top: -100px;
}

nav .icon-burger {
  display: none;
  position: absolute;
  right: 0px;
  top: 40%;
  transform: translate(-58%);
}
/* .nav-icons a{
  color:#DC3545;
} */
.nav-icons{
  padding-top:25px;
}
.nav-icons a{
  font-weight: bolder;
}
nav .icon-burger .line {
  width: 38px;
  height: 5px;
  background-color: #000;
  margin: 5px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.iconify {
   font-size: 20px;
   line-height: 1em;
}

@media screen and (max-width: 1100px) {
  nav{
      padding-right:5%;
      padding-left: 5%;
  }
}

@media screen and (max-width: 900px) {
  nav .logo {
    width: auto;
    margin-left: 0;
    justify-content: center;
  }

  nav .links a {
    font-size: 20px;
    color: #fff;
  }

  #res{
  margin-right: 0;
}

nav .links {
  float: none;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 100%;
  background: rgba(0, 0, 0, 0.9);
  width: auto;
  height: auto;
  justify-content: space-evenly;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

  nav .icon-burger {
    display: block;
  }

  nav :checked ~ .links {
    bottom: 0;
  }

  nav :checked ~ .icon-burger .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
  }

  .iconify {
   font-size: 25px;
   color: white;
   line-height: 1em;
}
}
@media screen and (max-width: 400px) {
  .logo_text p {
    font-size:.8em;
  }
  .logo_text .small {
    font-size: .6em !important;
  }
}
</style>