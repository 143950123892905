<template>
  <div>
    <nav-bar />
    <div class="placeholder">

    </div>
    <health />
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/New/Footer';
import Health from "../components/New/Health.vue";
import NavBar from "../components/New/NavBar.vue";

export default {
  components: {
    NavBar,
    Health,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.placeholder{
  height: 100px!important;
  border:1px solid red;
}
</style>